<template>
  <form @submit.prevent="submit">
    <div class="p-mb-3 p-input-filled">
      <div v-if="!editMode" class="p-field">
        <InputText
          placeholder="Email"
          type="text"
          class="user-email"
          autocomplete="email"
          v-model="formData.email"
          @blur="validation.formData.email.$touch()"
          :class="{ 'p-invalid': validation.formData.email.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.email"
          :rules="['required', 'email']"
        />
      </div>
      <h3 v-else class="p-m-0">{{ formData.email }}</h3>
    </div>
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <InputText
          placeholder="Full Name"
          type="text"
          class="user-name"
          v-model="formData.name"
          @blur="validation.formData.name.$touch()"
          :class="{ 'p-invalid': validation.formData.name.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.name"
          :rules="['required']"
        />
      </div>
    </div>
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <MultiSelect
          v-model="formData.roles"
          :options="roles"
          placeholder="Roles"
          display="chip"
          :class="{ 'p-invalid': validation.formData.roles.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.roles"
          :rules="['required']"
        />
      </div>
    </div>
    <div v-if="!editMode" class="p-mb-3 p-input-filled">
      <div class="p-field">
        <InputText
          type="password"
          placeholder="Password"
          class="user-password"
          autocomplete="new-password"
          v-model="formData.password"
          @blur="validation.formData.password.$touch()"
          :class="{ 'p-invalid': validation.formData.password.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.password"
          :rules="['required', 'minLength']"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      :label="label"
    />
  </form>
</template>

<script>
import { ref, watchEffect } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { roles } from '@/helpers/constants'

export default {
  components: { Button, InputText, MultiSelect, BaseValidationErrors },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Save User'
    },
    editMode: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: new Object()
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      email: null,
      name: null,
      roles: [],
      projects: [],
      password: null,
      active: true
    })

    const rules = {
      formData: {
        name: { required },
        roles: { required }
      }
    }
    if (!props.editMode) {
      rules.formData.email = { required, email }
      rules.formData.password = { required, minLength: minLength(6) }
    }

    const validation = useVuelidate(rules, { formData })

    watchEffect(() => {
      if (Object.keys(props.user).length) {
        formData.value = props.user
        formData.value.password = null
      }
    })

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value)
    }

    return { formData, roles, validation, submit }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
